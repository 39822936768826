.op {
    width: 100%;
    height: 70vh;
    background: rgba(0, 0, 0, 0.39);
    position: relative;
  }
  
  .op:before {
    content: '';
    position: absolute;
    background: url('../../assets/op-hero.jpg') no-repeat center top/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.op-content {
    width: 100%;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
    opacity: 0.5;
}

.op h1 {
    color: white;
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

    .op h2 {
        font-size: 1rem;
    }
    
    .op h1 {
        font-size: 2rem;
    }
    
    .op-content {
        display: block;
        position: absolute;
        top: 30%;
        padding: 1rem;
    }
    
    .op-content h1 {
        font-size: 3rem;
    }
    
    }