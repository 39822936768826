.activities {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .activities:before {
    content: '';
    position: absolute;
    background: url('../../assets/contact-form-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .1;
  }

  .actvities-content {
    margin: 1rem 0;
    padding: 2rem 0;
    width: 100%;
  }

  .activities-flex {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .activities-flex-left {
    width: 60%;
    margin: auto;
    padding: 1rem;
  }

  .activities-flex-left h1 {
    padding: 1rem 0;
    text-align: center;

  }

  .activities-flex-left ul {
    width: fit-content;
    padding: 1rem 0;
  }
  

  .activities-flex-right {
    padding: 1rem;
    width: 450px;
    margin: auto;
  }

  .activities-flex-right img {
    width: 100%;
    border-radius: 1rem;
  }

  @media  screen and (max-width:940px) {

    .activities-flex {
      flex-wrap: wrap;
    }

    .activities-flex-left {
        width: 100%;
      }

      .activities-flex-right {
        width: 100%;
      }

      .activities-flex-left ul {
        margin: auto 0;
      }

  }