/* Topbar Container */
.topbar {
    width: 100%;
    padding: 0.3rem;
    background-color: #005f59;
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.274);
}

.topbar .topbar-content {
    display: flex;
    justify-content: space-around;
    text-align: center;
    color: white;
    width: 100%;
}

.topbar .topbar-content p {
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Vertically align items */
    gap: 0.5rem; /* Add space between icon and text */
    padding: 0 1rem;
    font-size: 14px;
}

.topbar .bar-box1 {
    width: 27rem;
    border-right: 1px solid rgba(22, 116, 27, 0.616);
}

.topbar .bar-box2 {
    width: 15rem;
    border-right: 1px solid rgba(22, 116, 27, 0.616);
}

.topbar span {
    color: rgba(255, 255, 255, 0.616);
    font-size: 16px;
    font-weight: bold;
}

/* Hide elements for smaller screens */
@media screen and (max-width: 940px) {
    .topbar-hide {
        display: none;
    }
}
