.homeSection2 {
    padding:  1rem;
    margin: 2rem 0;
}

.homeSection2-content {
    display: flex;
    gap: 1rem;
    padding: 3rem 0;
}

.homeSection2-right {
    width: 50%;
    text-align: center;
}

.homeSection2-right img {
    width: 100%;
    border-radius: 1rem;
}

.homeSection2-left {
    width: 60%;
    padding: 2rem;
    margin: auto;
}

.homeSection2-content h1 {
    font-size: 1rem;
    padding: 1rem 0;
    letter-spacing: 5px;
    color: rgb(253, 146, 5);
}

.homeSection2-content h2 {
    font-size: 3rem;
    padding: 0 0 1rem 0;
    color: #011803;
}

.homeSection2-content p {
    font-size: 16px;
    text-align: left;
    color: rgb(0, 0, 0);
    margin-bottom: 0;
}

.checklist {
    display: flex;
    gap: 1rem;
}

.homeSection2-icon {
    font-size: 1.2rem;
    color: rgb(253, 146, 5);
}

@media screen and (max-width:940px) {
    .homeSection2-content {
        flex-wrap: wrap
    }

    .homeSection2 {
        padding: 1rem 1rem;
        margin: 1rem 0;
    }

    .homeSection2-right {
        width: 100%;
        margin: auto;
        padding: 1rem;
    } 

    .homeSection2-left {
        width: 100%;
    }

    .homeSection2 h1 {
        text-align: center;
}

    .homeSection2-content h1 {
    text-align: center;
}

    .homeSection2-content h2 {
    text-align: center;
}
    
}