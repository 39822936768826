.about-section {
    width: 100%;
    position: relative;
    background: rgba(255, 255, 255, 0);
}

.about-section .content {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 1rem;
}

.about-section p {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    color: rgb(0, 0, 0);
    margin: 1rem 0;
    font-size: 14px;
    line-height: 20px;
}

.about-section h1 {
    padding: 2rem 0;
    font-size: 2rem;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    color: var(--primary-color);
}



@media screen and (max-width: 940px) {
    .about-section {
        width: 100%;
    }

    .about-section h1 {
        font-size: 25px;
    }

    .about-section p {
        font-size: 15px;
    }
}






