@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.sa {
    width: 100%;
    height: 80vh;
    background: rgba(24, 23, 24, 0.521);
    position: relative;
  }
  
  .sa:before {
    content: '';
    position: absolute;
    background: url('../../assets/sa-submenu-bg.jpg') no-repeat center center/cover;
    height: 80vh;
    width: 100%;
    z-index: -1;
  }
  

.sa .sa-content {
    position: absolute;
    width: min-content;
    top: 30%;
    left: 10%;
    padding: 1rem;
}

.sa h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

    .sa .sa-content {
        width: 100%;
        top: 40%;
        left: 00%;
        padding: 1rem;
    }
    

.sa h1 {
    font-size: 3rem;
}
}