@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.navbar {
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 10;
    background: #16161600;
    padding: 1rem 0;
}

.navbar .navbar-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}


.navbar-logo {
  width: 280px;
  z-index: 2;
}

.navbar-logo img {
  width: 100%;
  padding: 0 1rem;
}

.navbar-logo h1 {
  padding: 1rem;
  color: white;
}

.navbar .icon {
  font-size: 1.4rem;
  color: rgb(255, 255, 255);
}

.navbar .logo .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: var(--primary-color);
}

.hamburger {
    display: none;
    padding: 1rem;
}

.navbar .nav-menu {
    height: 100%;
    align-items: center;
    display: flex;
    /* padding: 2rem 0; */
}

.navbar li {
    font-size: 14px;
    width: max-content;
    padding: .5rem .8rem;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
}

.navbar li a {
  font-weight: 300;
}

  .menu-item .sub__menus li a {
    color: #ffffff;
    font-size: 13px;
    padding: -1rem ;
  }

/* -----------------------------------------------------> SUB MENU */
.sub__menus {
    position: absolute;
    display: none;
    background: #005f59ea;
    width: 250px;
    padding: 2px 0 0 0;
    margin-top: 6px;
    z-index: 1000;
    border-radius: 5px;
  }

  /* -----------------------------------------------------> DROPDOWN ARROW */

  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    top: 10px;
    font-size: 12px;
  }


  .hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #0087ca;
  }
  
  .hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  
@media screen and (max-width: 1200px) {


    .navbar .hamburger {
        display: block;
        z-index: 15;
    }

    .navbar .nav-menu {
        position: absolute;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        top: 0;
        left:-100%;
        justify-content: center;
        background: var(--primary-color);
        transition: 0.5s ease-in;
    }

    .menu-item .sub__menus li {
      width: 90%;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.151);
      text-align: center;
      margin: auto;
    }


    .menu-item .sub__menus li a {
      color: #005f59;
      font-weight: 600;
      padding: 0 2rem;

    }

    .sub__menus {
      background: white;
      width: 100%;
      left: 0;
      border-radius: 0px;
    }

    .navbar .active {
        left: 0;
    }

    .navbar li {
        font-size: 1rem;
    }

    .navbar button {
        font-size: 1.8rem;
    }

    .menu-item .sub__menus li a {
        font-size: 14px;
      }

      li .submenu-header {
        font-size: 14px;
      }

      #submenu-item li {
        padding: .2rem 1rem;
      }


      .navbar .navbar-container {
        justify-content: space-between;
      }

      .navbar-logo {
        width: 180px;
      }

}


