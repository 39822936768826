@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.meth-section {
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0);
  position: relative;
}

.meth-section:before {
  content: '';
  position: absolute;
  background: url('../../assets/bg1.jpg') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.03;
}

.meth-section-content {
  padding: 3rem 1rem;
}

.meth-section-flex {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.meth-section-left {
  width: 50%;
  margin: auto;
}

.meth-section-left h1 {
  padding: 1rem 0;
}

.meth-section-right {
  width: 500px;
  margin: auto;
  -webkit-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
  -moz-box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
  box-shadow: 0px 0px 10px -5px rgba(10,9,10,1);
}

.meth-section-right img {
  width: 100%;
  border-radius: 15px;
  padding: .5rem;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

  .meth-section-flex {
    flex-wrap: wrap;
  }
  
  .meth-section-left {
    width: 100%;
    margin: auto;
  }
  
  .meth-section-right {
    width: 100%;
    margin: auto;
  }

  .meth-section-left h1 {
    text-align: center;
  }
  
  }



