@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.oxycodone {
    width: 100%;
    height: 100vh;
    background: #00000048;
    position: relative;
  }
  
  .oxycodone:before {
    content: '';
    position: absolute;
    background: url('../../assets/sa.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.oxycodone {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.oxycodone .oxycodone-content {
    display: block;
    position: absolute;
    top: 40%;
    left: 5%;
    padding: 1rem;
}

.oxycodone h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.oxycodone h1 {
    font-size: 3rem;
    text-align: center;
}

.oxycodone .oxycodone-content {
    width: 100%;
    top: 60%;
    left: 0%;
}

}